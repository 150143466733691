<template>
  <div class="trunk">
    <div class="Carousel">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide>
          <div class="panel-panel carouseImg1" @click="toProjectApply('FUTURE005')">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="panel-panel carouseImg2" @click="toProjectApply('FUTURE005')">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="panel-panel carouseImg3" @click="toProjectApply('FUTURE005')">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="panel-panel carouseImg4" @click="toProjectApply('FUTURE005')">
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="Head">
      <img src="../assets/images/homes/coin.png">
      <div class="panel">
        <div class="text1">赋能中国创业者和企业，开启财富自由的璀璨征程！</div>
        <div class="line"></div>
        <div class="text2">为有创业梦的人搭建广阔平台</div>
        <div class="text3">创业工场以“打造创业生态，一起成长共赢”为理念，“零门槛零成本零风险”创业为特点、“一站式服务”为保障的特色创业模式，助力中国创业者与企业，踏上实现财富自由的辉煌之路。</div>
        <a href="javascript:;" class="kscy" @click="toProjectApply('FUTURE005')">
          开始创业<i class="bi bi-arrow-right"></i>
        </a>
      </div>
    </div>
    <div class="DataList">
      <div class="panel">
        <div>
          <div class="p1">
            <div>1000万</div>
            <div class="bj">
              <div>+</div>
            </div>
          </div>
          <div class="p2">
            <span>注册资本1000万</span>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="panel">
        <div>
          <div class="p1">
            <div>5</div>
            <div class="bj">
              <div>+</div>
            </div>
          </div>
          <div class="p2">
            <span>5大服务领域</span>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="panel">
        <div>
          <div class="p1">
            <div>10000</div>
            <div class="bj">
              <div>+</div>
            </div>
          </div>
          <div class="p2">
            <span>扶持上万人成功创业</span>
          </div>
        </div>
      </div>
    </div>
    <div class="jx"></div>
    <div class="Service" id="Service">
      <div class="title">5大服务领域</div>
      <div class="details">对于中小企业，公司通过整合大企业的产业链供应链资源， 提供技术创新应用需求对接、新业务场景联合共创，助力企业拓展市场和合作机会</div>
      <div class="route-panel">
        <div class="panel">人力资源</div>
        <div class="panel">资金扶持</div>
        <div class="panel">项目资源</div>
        <div class="panel">人脉资源</div>
        <div class="panel">培训资源</div>
      </div>
      <div class="line"></div>
      <div class="card-panel">
        <div class="panel">
          <div class="icon"><img src="../assets/icons/HumanResources.png"></div>
          <div class="item-title">人力资源</div>
          <div class="item-details">创业工场汇聚了来自各行各业出类拔萃的创业合伙人，拥有丰富的创业经验，相互扶持共同成长。</div>
        </div>
        <div class="panel">
          <div class="icon"><img src="../assets/icons/FinancialSupport.png"></div>
          <div class="item-title">资金扶持</div>
          <div class="item-details">创业工场为每一位创业者提供创业资金扶持，做其坚强的后盾。</div>
        </div>
        <div class="panel">
          <div class="icon"><img src="../assets/icons/ProjectResources.png"></div>
          <div class="item-title">项目资源</div>
          <div class="item-details">创业工场深耕各领域拥有丰富的项目资源，以开放和包容的姿态，为创业者搭建实现梦想的平台。</div>
        </div>
        <div class="panel">
          <div class="icon"><img src="../assets/icons/NetworkingResources.png"></div>
          <div class="item-title">人脉资源</div>
          <div class="item-details">创业工场具备多年的线上营销实操经验,拥有完整的全网覆盖营销体系，助你获客无忧！</div>
        </div>
        <div class="panel">
          <div class="icon"><img src="../assets/icons/TrainingResources.png"></div>
          <div class="item-title">培训资源</div>
          <div class="item-details">创业工场提供丰富的培训课程，通过专业的培训，创业者能够快速提升自己的创业能力，为成功创业打下坚实的基础。</div>
        </div>
      </div>
    </div>
    <div class="Project">
      <div class="title">项目</div>

      <div class="ProCarousel">
        <div id="ProCarouselItem" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <div class="panel active" data-bs-target="#ProCarouselItem" data-bs-slide-to="0">未来全屏通CR眼镜</div>
            <div class="panel" data-bs-target="#ProCarouselItem" data-bs-slide-to="1">中国护眼灸</div>
            <!-- <div class="panel" data-bs-target="#ProCarouselItem" data-bs-slide-to="2">长春藤灸贴</div> -->
            <!-- <div class="panel" data-bs-target="#ProCarouselItem" data-bs-slide-to="3">长春藤保健</div> -->
            <div class="panel" data-bs-target="#ProCarouselItem" data-bs-slide-to="2">科技+A股</div>
            <!-- <div class="panel" data-bs-target="#ProCarouselItem" data-bs-slide-to="5">AI教培</div> -->
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="carousel-panel">
                <div class="letf" :style="{ backgroundImage: 'url(' + require('../assets/images/homes/c-cr.png') + ')' }"></div>
                <div class="right">
                  <p>未来全屏通CR眼镜</p>
                  <div>给你7000 元开启创富梦，无线全屏通眼镜引领未来路，无风险创业无忧大胆行！你一定行！如果你渴望创业，
                    追求成功，并且对科技产品充满热情，那么这个无风险消费创富项目将是你实现梦想的绝佳机会。加入我们，
                    一起开启这场充满挑战与机遇的创业之旅，用全球首款无线全屏通 CR 智能眼镜创造属于你的辉煌未来！</div>
                  <a href="javascript:;" class="kscy" @click="toProjectApply('FUTURE001')">
                    开始创业<i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="carousel-panel">
                <div class="letf" :style="{ backgroundImage: 'url(' + require('../assets/images/homes/c-hyj.png') + ')' }"></div>
                <div class="right">
                  <p>中国护眼灸</p>
                  <div>关注眼睛商机，铸就精彩视界自主研发生产，一站式保姆式扶持我们有完
                    整产业链资源，近视防控家庭综合解决方案</div>
                  <a href="javascript:;" class="kscy" @click="toProjectApply('FUTURE002')">
                    开始创业<i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="carousel-item">
              <div class="carousel-panel">
                <div class="letf"></div>
                <div class="right">
                  <p>长春藤灸贴</p>
                  <div></div>
                  <a href="javascript:;" class="kscy" @click="toProjectApply('FUTURE003')">
                    开始创业<i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div> -->
            <!-- <div class="carousel-item">
              <div class="carousel-panel">
                <div class="letf"></div>
                <div class="right">
                  <p>长春藤灸贴</p>
                  <div></div>
                  <a href="javascript:;" class="kscy" @click="toProjectApply('FUTURE004')">
                    开始创业<i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div> -->
            <div class="carousel-item">
              <div class="carousel-panel">
                <div class="letf" :style="{ backgroundImage: 'url(' + require('../assets/images/homes/c-a.png') + ')' }">
                </div>
                <div class="right">
                  <p>科技+A股</p>
                  <div>独特的科技+A股创业支持模式你只需提供一个证券账户，就可获得我们创业工场提供的高达4~40的
                    万启动资金。专业投顾手把手指导陪跑，助你创业无忧。</div>
                  <a href="javascript:;" class="kscy" @click="toProjectApply('FUTURE005')">
                    开始创业<i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="carousel-item">
              <div class="carousel-panel">
                <div class="letf"></div>
                <div class="right">
                  <p>AI教培</p>
                  <div></div>
                  <a href="javascript:;" class="kscy" @click="toProjectApply('FUTURE006')">
                    开始创业<i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="page">
          <a href="javascript:;" data-bs-target="#ProCarouselItem" data-bs-slide="prev">
            <div class="btns"><i class="bi bi-chevron-left"></i></div>
          </a>
          <a href="javascript:;" data-bs-target="#ProCarouselItem" data-bs-slide="next">
            <div class="btns"><i class="bi bi-chevron-right"></i></div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.swiper-pagination-bullet-active {
  background: var(--fontColor);
  opacity: 1;
}
</style>
<style scoped lang="less">
.trunk {
  // background-color: #f5f5f5;
  color: #585858;
  background-color: #f5f5f5;
}

.title {
  font-size: 36px;
  font-weight: bold;
  color: #353535;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  max-width: 90%;
}

.details {
  text-align: center;
  max-width: 90%;
  margin: auto;
}

.kscy {
  color: #ffffff;
  background-color: var(--fontColor);
  padding: 8px 20px;
  font-size: 18px;
  font-weight: bold;

  i {
    margin-left: 10px;
  }
}

.Head,
.DataList,
.Service,
.Project {
  position: relative;
  z-index: 0;
  max-width: 1200px;
  margin: auto;
}

.Carousel {
  .panel-panel {
    width: 100%;
    height: calc(100vh - 60px);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .carouseImg1 {
    @media (max-width:600px) {
      background-image: url(../assets/images/homes/m-banner1.png);
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      background-image: url(../assets/images/homes/i-banner1.png);
    }

    @media (min-width: 1200px) {
      background-image: url(../assets/images/homes/banner1.png);
    }
  }

  .carouseImg2 {
    @media (max-width:600px) {
      background-image: url(../assets/images/homes/m-banner2.png);
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      background-image: url(../assets/images/homes/i-banner2.png);
    }

    @media (min-width: 1200px) {
      background-image: url(../assets/images/homes/banner2.png);
    }
  }

  .carouseImg3 {
    @media (max-width:600px) {
      background-image: url(../assets/images/homes/m-banner3.png);
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      background-image: url(../assets/images/homes/i-banner3.png);
    }

    @media (min-width: 1200px) {
      background-image: url(../assets/images/homes/banner3.png);
    }
  }
  .carouseImg4 {
    @media (max-width:600px) {
      background-image: url(../assets/images/homes/m-banner4.png);
    }

    @media (min-width: 600px) and (max-width: 1200px) {
      background-image: url(../assets/images/homes/i-banner4.png);
    }

    @media (min-width: 1200px) {
      background-image: url(../assets/images/homes/banner4.png);
    }
  }

}

.Head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: 50px;

  img {
    width: 550px;
    max-width: 90vw;
    margin: 10px auto;
  }

  .panel {
    width: 550px;
    max-width: 90vw;
    margin: 10px auto;

    .text1 {
      color: var(--fontColor);
      font-size: 36px;
      font-weight: bold;
    }

    .line {
      width: 20px;
      height: 0;
      border: 1px solid var(--fontColor);
      margin: 15px 0;
    }

    .text2 {
      font-size: 20x;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .text3 {
      margin-bottom: 80px;
    }
  }
}

.jx {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 350px;
  margin-top: -90px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../assets/images/homes/jx.png');
}

.DataList {
  color: var(--fontColor);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;

  .panel {
    background-color: #ffffff;
    width: 400px;
    max-width: 90%;
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .line {
      width: 1px;
      height: 60px;
      background-color: #B8B8B8;
      position: absolute;
      top: 60px;
      right: 0;
    }

    .p1 {
      font-size: 32px;
      font-weight: bold;
      // background-color: salmon;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      padding-left: 20px;

      .bj {
        min-height: 65px;
        // background-color: seagreen;
        font-size: 24px;
      }
    }

    .p2 {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #585858;
    }
  }
}

.Service {

  .route-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #ffffff;
    column-gap: 100px;
    position: relative;
    z-index: 5;
    margin-top: 40px;

    .panel {
      background-color: var(--fontColor);
      min-width: 130px;
      min-height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      white-space: nowrap;
    }
  }

  .line {
    border-top: 1px dashed #585858;
    width: 100%;
    height: 0;
    margin-top: -20px;
    margin-bottom: 80px;
    position: relative;
    z-index: 1;
  }

  .card-panel {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;

    .panel {
      width: 350px;
      min-height: 245px;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 30px;

      .icon {
        width: 100%;
        height: 50px;
        // background-color: sandybrown;
        text-align: center;

        img {
          width: auto;
          height: 100%;
        }
      }

      .item-title {
        width: 100%;
        min-height: 50px;
        line-height: 50px;
        text-align: center;
        // background-color: seagreen;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 2px;
      }

      .item-details {
        width: 100%;
        // background-color: skyblue;
        margin-top: 10px;
      }
    }
  }
}

.Project {
  .carousel-indicators {
    width: 100%;
    height: auto;
    // background-color: aquamarine;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    font-size: 20px;
    margin-bottom: 50px;

    [data-bs-target] {
      position: relative;
      border: 0px;
      border-radius: 0;
      width: auto;
      min-height: 40px;
      box-sizing: content-box;
      flex: none;
      padding: 0;
      margin-right: 0;
      margin-left: 0;
      text-indent: 0;
      background-color: rgba(0, 0, 0, 0);
      background-clip: 0;
      border-top: 1;
      border-bottom: 1;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    [data-bs-target]:hover {
      color: var(--fontColor);
      border-bottom: 2px solid var(--fontColor);
    }

    .active {
      color: var(--fontColor);
      border-bottom: 2px solid var(--fontColor);
    }
  }

  .ProCarousel {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;

    .carousel-panel {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 390px;
      // background-color: salmon;
      display: flex;
      justify-content: center;

      .letf {
        position: relative;
        z-index: 3;
        width: 545px;
        height: 100%;
        // background-color: sandybrown;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover
      }

      .right {
        position: relative;
        z-index: 3;
        width: 655px;
        height: auto;
        background-color: #ffffff;
        padding: 30px;

        p {
          font-size: 24px;
          font-weight: bold;
        }

        a {
          position: absolute;
          z-index: 4;
          left: 40px;
          bottom: 50px;
        }
      }
    }

    .page {
      position: absolute;
      z-index: 5;
      bottom: 195px;
      left: -75px;
      width: 1350px;
      max-width: 100vw;
      min-height: 40px;
      // background-color: salmon;
      display: flex;
      justify-content: space-between;

      .btns {
        width: 40px;
        height: 40px;
        background-color: var(--fontColor);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 20px;
      }
    }
  }
}

@media (max-width:600px) {
  .DataList {
    gap: 20px;

    .panel {
      .line {
        display: none;
      }
    }
  }

  .jx {
    margin-top: 40px;
  }

  .Service {
    .route-panel {
      row-gap: 20px;
      margin-bottom: 30px;
    }

    .line {
      display: none;
    }
  }

  .Project {
    .carousel-indicators {
      width: 90%;
      row-gap: 20px;
      column-gap: 20px;
      justify-content: center;

    }

    .carousel-inner {
      width: 90%;
      margin: auto;
    }

    .ProCarousel {
      .carousel-panel {
        min-height: 780px;
        display: block;

        .letf {
          width: 100%;
          height: 390px;
        }

        .right {
          width: 100%;
          min-height: 390px;
        }
      }

      .page {
        left: 0;
        display: none;
      }

      ;
    }
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .DataList {
    gap: 20px;

    .panel {
      width: 320px;

      .line {
        display: none;
      }
    }
  }

  .jx {
    margin-top: 40px;
  }

  .Service {
    .route-panel {
      row-gap: 20px;
      margin-bottom: 30px;
    }

    .line {
      display: none;
    }
  }

  .Project {
    .carousel-indicators {
      width: 90%;
    }

    .ProCarousel {
      .page {
        left: 0;
        display: none;
      }

      ;
    }
  }
}
</style>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: 'HomeSwiper',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      isMobile: false,
      swiperOption: {
        loop: true,//可选选项，开启循环
        autoplay: true,//可选选项，自动滑动
        pagination: {
          el: '.swiper-pagination',
          // bulletActiveClass: 'my-bullet-active',
        },
        paginationType: 'fraction',
        paginationClickable: true,
      },
    }
  },
  mounted() {

  },
  beforeDestroy() {

  },
  created() {

  },
  methods: {
    toProjectApply(code) {
      if(code != 'FUTURE005'){
        alert('该创业计划正在筹备中！');
        return;
      }
      this.$router.push({ path: '/project', query: { code: code } }).catch(ca => ca);
    },
  }
}
</script>